import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "296",
  height: "67",
  viewBox: "0 0 296 67",
  fill: "#662E9C"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "inherit",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M67.209 33.4751C67.209 51.9974 52.2139 66.9504 33.8545 66.9504C15.4951 66.9504 0.5 51.9974 0.5 33.4751C0.5 14.9528 15.399 0 33.8545 0C52.31 0 67.209 15.0493 67.209 33.4751ZM51.5891 33.4751C51.5891 23.6834 43.6109 15.6764 33.8545 15.6764C24.0981 15.6764 16.1199 23.6834 16.1199 33.4751C16.1199 43.2669 24.0981 51.2739 33.8545 51.2739C43.6109 51.2739 51.5891 43.2669 51.5891 33.4751ZM140.117 33.4751C140.117 51.9974 125.122 66.9504 106.763 66.9504C88.4036 66.9504 73.4085 51.9974 73.4085 33.4751C73.4085 14.9528 88.3075 0 106.763 0C125.218 0 140.117 15.0493 140.117 33.4751ZM124.498 33.4751C124.498 23.6834 116.519 15.6764 106.763 15.6764C97.0065 15.6764 89.0284 23.6834 89.0284 33.4751C89.0284 43.2669 97.0065 51.2739 106.763 51.2739C116.519 51.2739 124.498 43.2669 124.498 33.4751ZM195.098 15.0974V1.06091H144.538V15.0974H161.215V65.8409H178.421V15.0974H195.098ZM258.203 47.222C258.203 60.535 249.167 65.8409 226.482 65.8409H202.259V1.06091H226.482C248.35 1.06091 257.145 6.36686 257.145 19.6798C257.145 26.3362 254.262 30.7739 247.918 33.3786C255.079 35.7903 258.251 40.3244 258.251 47.222H258.203ZM219.465 26.4327H226.482C236.815 26.4327 239.939 25.0821 239.939 20.741C239.939 16.3998 236.815 15.0492 226.482 15.0492H219.465V26.3844V26.4327ZM240.997 46.1368C240.984 41.8147 237.564 40.4691 226.482 40.4691H219.465V51.8044H226.482C237.564 51.8044 240.984 50.4589 240.997 46.1368ZM295.5 65.8409V51.8044H289.06V15.0974H295.5V1.06091H265.366V15.0974H271.806V51.8044H265.366V65.8409H295.5Z"
    }, null, -1)
  ])))
}
export default { render: render }